export const environment = {
    production: false,
    name: 'laytrip',
    code: 'LT',
    stage: 'staging',
    version: 1,
    apiUrl:'https://api-staging.laytrip.com/',
    google_places_api_key: "AIzaSyB7Ws9zJ9ozVruSjF2N3pDVsqHF-h1QtBU",
    s3BucketUrl: 'https://d2q1prebf1m2s9.cloudfront.net/',
    fb_api_key:'933948490440237',
    google_client_id:'154754991565-9lo2g91remkuefocr7q2sb92g24jntba.apps.googleusercontent.com',
    siteUrl: 'https://staging.laytrip.com',
    VAPID_PUBLIC_KEY:'BLqKtvo8fc7ZHJ5m2j4RYbAoJJ6WtdUYNH38ZaStSIuecp1sCcrE7CFkFT266FCpeky4nBR5_yNgxygoLqE3jJ4',
    googleTranslationKey: 'AIzaSyAM2IBT7FXhbv1NFqqVEdYkFDTyqPUhmR8',
    recaptcha_secret_key: "6LcPUboZAAAAAN1Sh411ruqdmT4OD6ZDe65rTsVO",
    clientId: 'com.laytripweb',
    cryptoKey: 'H4rdT0Gu3ssP@ssw0rd!'
  };